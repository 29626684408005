export const date = 'Virheellinen päivämäärä.'
export const email = 'Virheellinen sähköpostiosoite.'
export const iban = 'Virheellinen IBAN-numero.'
export const invalid = 'Virheellinen arvo.'
export const invalidFileType = 'Virheellinen tiedostotyyppi.'
export const maxCount = 'Tämä kenttä saa sisältää enintään {0} kohdetta.'
export const maxDate = 'Anna päivämäärä ennen {0}.'
export const maxDecimals =
  'Tämän arvon tulee sisältää enintään {0} desimaalia. Varo käyttämästä tuhaterotin.'
export const maxFileSize = 'Valitse tiedosto, joka on pienempi kuin {0}.'
export const maxFilesSize = 'Kaikkien tiedostojen on oltava pienempiä kuin {0}.'
export const maxLength = 'Tämän arvon on oltava alle {0} merkkiä pitkä.'
export const maxValue = 'Tämän arvon on oltava pienempi tai yhtä suuri kuin {0}.'
export const minCount = 'Tämän kentän on sisällettävä vähintään {0} kohdetta.'
export const minDate = 'Anna päivämäärä jälkeen {0}.'
export const minFileSize = 'Valitse tiedosto, joka on suurempi kuin {0}.'
export const minFilesSize = 'Kaikkien tiedostojen on oltava suurempia kuin {0}.'
export const minLength = 'Tämän arvon on oltava pidempi kuin {0} merkkiä.'
export const minValue =
  'Tämän arvon on oltava suurempi tai yhtä suuri kuin {0}. Varo käyttämästä tuhaterotin.'
export const pattern = 'Tämä arvo ei noudata oikeaa muotoa.'
export const phone = 'Virheellinen puhelinnumero.'
export const phoneCountry = 'Virheellinen {0} puhelinnumero.'
export const phoneFixedLine = 'Virheellinen lankapuhelinnumero.'
export const phoneIntl = 'Varmista, että aloitat +xx maakoodillasi.'
export const phoneMobile = 'Virheellinen matkapuhelinnumero.'
export const required = 'Arvo vaaditaan.'
export const requiredButton = 'Ole hyvä ja paina tätä painiketta.'
export const requiredCard = 'Ole hyvä ja klikkaa {0}.'
export const requiredChoice = 'Ole hyvä ja valitse yksi.'
export const requiredChoices = 'Valitse yksi tai useampi.'
export const requiredDate = 'Voimassa oleva päivämäärä vaaditaan.'
export const requiredDatetime = 'Voimassa oleva päivämäärä ja aika vaaditaan.'
export const requiredDownload = 'Kaikki tiedostot on ladattava tämän vaiheen vahvistamiseksi.'
export const requiredFile = 'Tiedosto vaaditaan.'
export const requiredFileMultiple = 'Ole hyvä ja anna vähintään yksi tiedosto.'
export const requiredSignature = 'Ole hyvä ja allekirjoita.'
export const requiredRating = 'Valitse arvo 1 ja 5 välillä.'
export const requiredChecked = 'Ole hyvä ja valitse tämä valintaruutu.'

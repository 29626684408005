export const date = 'Invalid date.'
export const email = 'Invalid email address.'
export const iban = 'Invalid IBAN number.'
export const invalid = 'Invalid value.'
export const invalidFileType = 'Invalid file type.'
export const maxCount = 'This field should contain at most {0} items.'
export const maxDate = 'Enter a date before {0}.'
export const maxDecimals =
  'This value must contain at most {0} decimal places. Be careful not to use a thousands separator.'
export const maxFileSize = 'Select a file smaller than {0}.'
export const maxFilesSize = 'All files must be smaller than {0}.'
export const maxLength = 'This value must be less than {0} characters long.'
export const maxValue = 'This value must be smaller or equal to {0}.'
export const minCount = 'This field should contain at least {0} items.'
export const minDate = 'Enter a date after {0}.'
export const minFileSize = 'Select a file larger than {0}.'
export const minFilesSize = 'All files must be larger than {0}.'
export const minLength = 'This value must be more than {0} characters long.'
export const minValue =
  'This value must be greater or equal to {0}. Be careful not to use a thousands separator.'
export const pattern = 'This value does not respect the right format.'
export const phone = 'Invalid phone number.'
export const phoneCountry = 'Invalid {0} phone number.'
export const phoneFixedLine = 'Invalid land-line number.'
export const phoneIntl = 'Make sure to start with your +xx country code.'
export const phoneMobile = 'Invalid mobile number.'
export const required = 'A value is required.'
export const requiredButton = 'Please click on this button.'
export const requiredCard = 'Please click on {0}.'
export const requiredChoice = 'Please pick one.'
export const requiredChoices = 'Pick one or more.'
export const requiredDate = 'A valid date is required.'
export const requiredDatetime = 'A valid date and time are required.'
export const requiredDownload = 'All the files must have been downloaded to validate this step.'
export const requiredFile = 'A file is required.'
export const requiredFileMultiple = 'Please provide at least one file.'
export const requiredSignature = 'Please sign.'
export const requiredRating = 'Pick a value between 1 and 5.'
export const requiredChecked = 'Please check this box.'

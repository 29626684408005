import { flatten, ifString, isArray, isIterable } from '@penbox-io/stdlib'

import { normalizeError, ifFormHelpable, Dict, ifDict, isDict } from '../common'

import { FormElement, DefinitionMap, GenericElement, FormElements } from './definition.js'

export type ValueGetter = (key: string) => any

function isValidElementInput(item: unknown): item is Readonly<Dict<unknown>> & {
  type: string
  key?: string
} {
  if (!isDict(item)) return false

  const type = item.type
  if (!type || typeof type !== 'string' || type === '__proto__') return false

  return true
}

export function buildElements<D extends DefinitionMap>(
  definitions: D,
  input: unknown,
  locale: string,
  ensureSubmittable: boolean,
  getValue?: ValueGetter,
  elementTransform?: (element: unknown) => unknown,
  timeZone?: string
): FormElements<D> {
  const elements: FormElements<D> = []

  let hasSubmitElement = false

  const iterable = isArray(input) ? flatten(input) : isIterable(input) ? input : null
  if (iterable) {
    for (const raw of iterable) {
      try {
        const element = buildElement(definitions, raw, locale, getValue, elementTransform, timeZone)
        if (element) {
          element.internalId ||= nextId(elements, `__element:${element.type}:`)
          elements.push(element as FormElement<D>)
          hasSubmitElement ||= element.type === 'submit'
        }
      } catch (err) {
        console.warn("Couldn't parse element", raw, err)
      }
    }
  }

  if (
    ensureSubmittable &&
    !hasSubmitElement &&
    definitions.submit &&
    // elements.at(-1) not yet (?) available
    (elements[elements.length - 1]?.options as any)?.submitOnChange !== true
  ) {
    const raw = { type: 'submit' }
    const element = buildElement(definitions, raw, locale, getValue, elementTransform, timeZone)
    if (element) {
      element.internalId ||= nextId(elements, `__element:${element.type}:`)
      elements.push(element as FormElement<D> & { internalId: string })
      hasSubmitElement ||= element.type === 'submit'
    }
  }

  return elements
}

export function buildElement<D extends DefinitionMap>(
  definitions: D,
  raw: unknown,
  locale: string,
  getValue?: ValueGetter,
  elementTransform?: (element: unknown) => unknown,
  timeZone?: string
): null | FormElement<D> {
  const item = elementTransform ? elementTransform(raw) : raw
  if (!item) return null
  if (!isValidElementInput(item)) return null

  const definition = definitions[item.type]
  if (!definition) return null

  const options = definition.options(item, locale)

  const { type } = item
  // If key is not prefixed by data, enforce it
  const key = item.key && !item.key?.includes?.('.') ? `data.${item.key}` : item.key

  const isGettable = getValue != null && key != null
  const getterValue = isGettable ? getValue(key) : undefined
  const valueRaw = getterValue === undefined ? item.value : getterValue

  const defined = valueRaw !== undefined
  const required =
    item.required === true && definition.requirable?.(options) !== false
      ? // If element.value is not defined, then we need to make sure that
        // it can be retrieved (using getValue(key)) to mark it as
        // required
        defined || isGettable
      : false

  const def = definition.parse(options, locale, item.default ?? null)
  const value =
    valueRaw === undefined
      ? def // Default will apply if value is not defined
      : definition.parse(options, locale, valueRaw)

  const error =
    (item.error ? normalizeError(item.error) : null) ||
    definition.validate(options, locale, value, required)

  const element = {
    id: ifString(item.id),
    type,
    options,
    meta: ifDict(item.meta),
    locale,
    title: !item.title ? null : ifFormHelpable(item.title) ?? null,
    error,
    key,
    defined,
    value,
    default: def,
    required,
  } as FormElement<D>

  const localized = definition.localize
    ? definition.localize(element, locale, timeZone)
    : definition.stringify(element)

  element.localized = localized

  return element
}

const nextId = (elements: GenericElement[], prefix: string) => {
  let count = 0
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].internalId?.startsWith(prefix)) count++
  }
  return `${prefix}${count}`
}

export function isElementWithKey<E extends FormElement<DefinitionMap>>(
  element: E
): element is E & { key: NonNullable<E['key']> } {
  return element.key != null
}

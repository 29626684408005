import { isArray, isNumber, isString } from '@penbox-io/stdlib'
import { WorkflowNotificationReminderUnit, WorkflowNotifications } from './step'

export function parseAssignedTo(assignedTo?: unknown | null): { type: string; key: string } | null {
  if (!assignedTo || typeof assignedTo !== 'object') {
    return null
  }

  const { type, key } = assignedTo as { type: string; key: string }

  if (!type) {
    return null
  }

  if (!['user', 'contact'].includes(type)) {
    return null
  }

  if (type === 'user') {
    return {
      type: 'user',
      key: key ?? 'owner',
    }
  }

  if (type === 'contact') {
    return {
      type: 'contact',
      key: key ?? 'main',
    }
  }

  return null
}

function parseEmailNotification(emailNotification: any | null):
  | {
      subject?: string
      body?: string
      from?: string
      cc?: string[]
      bcc?: string[]
      reply_to?: string
    }
  | undefined {
  if (
    !emailNotification ||
    typeof emailNotification !== 'object' ||
    Array.isArray(emailNotification)
  ) {
    return undefined
  }

  const emailDefinition = {
    subject: isString(emailNotification.subject) ? emailNotification.subject : undefined,
    body: isString(emailNotification.body) ? emailNotification.body : undefined,
    from: isString(emailNotification.from) ? emailNotification.from : undefined,
    cc: isArray(emailNotification.cc) ? emailNotification.cc : undefined,
    bcc: isArray(emailNotification.bcc) ? emailNotification.bcc : undefined,
    reply_to: isString(emailNotification.reply_to) ? emailNotification.reply_to : undefined,
  }

  if (emailDefinition.subject || emailDefinition.body) {
    return emailDefinition
  }

  return undefined
}

function parseSmsNotification(smsNotification: any | null):
  | {
      body?: string
    }
  | undefined {
  if (!smsNotification || typeof smsNotification !== 'object' || Array.isArray(smsNotification)) {
    return undefined
  }

  const smsDefinition = {
    body: isString(smsNotification.body) ? smsNotification.body : undefined,
  }

  if (smsDefinition.body) {
    return smsDefinition
  }

  return undefined
}

function parseReminders(reminders: any | null) {
  if (!reminders || typeof reminders !== 'object' || !Array.isArray(reminders)) {
    return undefined
  }

  const reminderDefinitions = []

  for (const reminder of reminders) {
    const count: number = isNumber(reminder.count) ? reminder.count : undefined
    const unit: WorkflowNotificationReminderUnit = isString(reminder.unit)
      ? reminder.unit.toLowerCase()
      : undefined

    if (!count || !unit) {
      continue
    }

    if (!['hours', 'days', 'weeks', 'months'].includes(unit)) {
      continue
    }

    const reminderDefinition = {
      count: count,
      unit: unit,
      options: {
        email: parseEmailNotification(reminder.options?.email),
        sms: parseSmsNotification(reminder.options?.sms),
      },
    }

    if (reminderDefinition.options?.email || reminderDefinition.options?.sms) {
      reminderDefinitions.push(reminderDefinition)
    }
  }

  if (reminderDefinitions.length > 0) {
    return reminderDefinitions
  }

  return undefined
}

export function parseNotifications(notifications: unknown | null): WorkflowNotifications | null {
  if (!notifications || typeof notifications !== 'object' || Array.isArray(notifications)) {
    return null
  }

  const { email, sms, reminders } = notifications as { email?: any; sms?: any; reminders?: any }

  if (!email && !sms && !reminders) {
    return null
  }

  const workflowNotifications: WorkflowNotifications = {
    email: parseEmailNotification(email),
    sms: parseSmsNotification(sms),
    reminders: parseReminders(reminders),
  }

  if (workflowNotifications.email || workflowNotifications.sms || workflowNotifications.reminders) {
    return workflowNotifications
  }

  return null
}

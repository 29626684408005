export const date = 'Veuillez fournir une date valide.'
export const email = 'Veuillez fournir une adresse email valide.'
export const iban = 'Veuillez fournir un numéro IBAN valide.'
export const invalid = "Cette valeur n'est pas valide."
export const invalidFileType = 'Type de fichier invalide.'
export const maxCount = 'Ce champ doit contenir {0} éléments maximum.'
export const maxDate = 'Veuillez rentrer une date antérieure au {0}.'
export const maxDecimals =
  'Cette valeur doit contenir au plus {0} décimales. Veillez à ne pas utiliser de séparateur de milliers.'
export const maxFileSize = 'Veuillez sélectionner un ficher faisant au plus {0}.'
export const maxFilesSize = 'Tous les fichers doivent faire au plus {0}.'
export const maxLength = 'Cette valeur doit contenir {0} caractères maximum.'
export const maxValue = 'Cette valeur doit être inférieure ou égale à {0}.'
export const minCount = 'Ce champ doit contenir {0} éléments minimum.'
export const minDate = 'Veuillez rentrer une date postérieure au {0}.'
export const minFileSize = 'Veuillez sélectionner un ficher faisant au minimum {0}.'
export const minFilesSize = 'Tous les fichers doivent faire au minimum {0}.'
export const minLength = 'Cette valeur doit contenir {0} caractères minimum.'
export const minValue =
  'Cette valeur doit être supérieure ou égale à {0}. Veillez à ne pas utiliser de séparateur de milliers.'
export const pattern = 'Cette valeur ne respecte pas le bon format.'
export const phone = 'Numéro de téléphone invalide.'
export const phoneCountry = 'Numéro de téléphone {0} invalide.'
export const phoneFixedLine = 'Numéro de ligne fixe non valide.'
export const phoneIntl = 'Veuillez spécifier le préfixe du pays (+xx).'
export const phoneMobile = 'Numéro de téléphone mobile non valide.'
export const required = 'Veuillez remplir ce champ.'
export const requiredButton = 'Veuillez cliquer sur ce bouton.'
export const requiredCard = 'Veuillez cliquer sur {0}.'
export const requiredChoice = "Veuillez choisir l'une des options."
export const requiredChoices = 'Veuillez choisir une option.'
export const requiredDate = 'Veuillez fournir une date valide.'
export const requiredDatetime = 'Veuillez fournir une date et une heure valides.'
export const requiredDownload =
  'Tous les fichiers doivent avoir été téléchargés pour valider cette étape.'
export const requiredFile = 'Veuillez fournir un fichier.'
export const requiredFileMultiple = 'Veuillez fournir au moins un fichier.'
export const requiredSignature = 'Veuillez signer.'
export const requiredRating = 'Veuillez choisir une note entre 1 et 5.'
export const requiredChecked = 'Veuillez cocher cette case.'

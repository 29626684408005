import { type Definition } from '../../core'

import {
  type Options,
  type Value,
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} from '../partials/datetime.js'
import { parseDate } from '../utils/date'

function localize(
  element: { value: unknown },
  locale: string,
  timeZone?: string
): string | undefined {
  const date = parseDate(element.value)
  if (!date) return undefined

  return new Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
    timeStyle: 'short',
    timeZone: timeZone ?? 'Europe/Brussels',
  }).format(date)
}

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
  localize,
} satisfies Definition<Options, Value>

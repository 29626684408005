export const date = 'Ugyldig dato.'
export const email = 'Ugyldig emailadresse.'
export const iban = 'Ugyldigt IBAN-nummer.'
export const invalid = 'Ugyldig værdi.'
export const invalidFileType = 'Ugyldig filtype.'
export const maxCount = 'Dette felt bør indeholde højst {0} elementer.'
export const maxDate = 'Indtast en dato før {0}.'
export const maxDecimals =
  'Denne værdi må højst indeholde {0} decimaler. Sørg for ikke at bruge tusind adskiller.'
export const maxFileSize = 'Vælg en fil, der er mindre end {0}.'
export const maxFilesSize = 'Alle filer skal være mindre end {0}.'
export const maxLength = 'Denne værdi må ikke være længere end {0} tegn.'
export const maxValue = 'Denne værdi skal være mindre eller lig med {0}.'
export const minCount = 'Dette felt bør indeholde mindst {0} elementer.'
export const minDate = 'Indtast en dato efter {0}.'
export const minFileSize = 'Vælg en fil, der er større end {0}.'
export const minFilesSize = 'Alle filer skal være større end {0}.'
export const minLength = 'Denne værdi skal være længere end {0} tegn.'
export const minValue =
  'Denne værdi skal være større eller lig med {0}. Sørg for ikke at bruge tusind adskiller.'
export const pattern = 'Denne værdi overholder ikke det rigtige format.'
export const phone = 'Ugyldigt telefonnummer.'
export const phoneCountry = 'Ugyldigt {0} telefonnummer.'
export const phoneFixedLine = 'Ugyldigt fastnetnummer.'
export const phoneIntl = 'Sørg for at starte med din +xx landekode.'
export const phoneMobile = 'Ugyldigt mobilnummer.'
export const required = 'En værdi er påkrævet.'
export const requiredButton = 'Klik venligst på denne knap.'
export const requiredCard = 'Klik venligst på {0}.'
export const requiredChoice = 'Vælg venligst en.'
export const requiredChoices = 'Vælg en eller flere.'
export const requiredDate = 'En gyldig dato er påkrævet.'
export const requiredDatetime = 'En gyldig dato og tid er påkrævet.'
export const requiredDownload = 'Alle filer skal være downloadet for at bekræfte dette trin.'
export const requiredFile = 'En fil er påkrævet.'
export const requiredFileMultiple = 'Giv venligst mindst én fil.'
export const requiredSignature = 'Venligst underskriv.'
export const requiredRating = 'Vælg en værdi mellem 1 og 5.'
export const requiredChecked = 'Venligst marker denne boks.'

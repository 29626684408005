import { isArray, isString } from '@penbox-io/stdlib'
import { IStepDefinitionParser } from '../core/definition'
import {
  ValueGetterResponse,
  WorkflowNotifications,
  WorkflowStep,
  WorkflowStepRequestBaseWithNotificationsState,
} from '../core/step'
import { parseAssignedTo, parseNotifications } from '../core/definitionHelper'

export type FormWorkflowStep = WorkflowStep & {
  properties: {
    body: string
    subject: string
    options?: {}
    form?: []
    flow_customization?: string
    flow?: string
    form_title?: string
  }
  state: WorkflowStepRequestBaseWithNotificationsState | null
  notifications?: WorkflowNotifications | null
}

class Form implements IStepDefinitionParser<FormWorkflowStep> {
  parse(step: any, value?: ValueGetterResponse | null): FormWorkflowStep {
    const key = isString(step.key) ? step.key : ''

    return {
      key,
      type: 'form',
      title: isString(step.title) ? step.title : step.properties?.form_title || key,
      assigned_to: parseAssignedTo(step.assigned_to),
      properties: {
        options: step.properties?.options,
        form: isArray(step.properties?.form) ? step.properties.form : [],
        body: step.properties?.body ?? '',
        subject: step.properties?.subject ?? '',
        flow_customization: step.properties?.flow_customization,
        flow: step.properties?.flow,
        form_title: step.properties?.form_title,
      },
      notifications: parseNotifications(step.notifications),
      state: value?.request
        ? {
            id: value.request.id,
            type: 'request',
            status: value.request.status,
            completed_at: value.request.completed_at,
            active_from: value.request.active_from,
            declined_at: value.request.declined_at,
            owner: value.request.owner,
            active_until: value.request.active_until,
            processed_at: value.request.processed_at,
            link: value.request.link,
            seen_at: value.request.seen_at,
            contact: value.request.user,
            notifications: value.request.notifications
              ? value.request.notifications.map((x) => ({ ...x, type: 'notification' }))
              : [],
            executed_at: value.executed_at,
          }
        : null,
    }
  }
}

export default new Form()

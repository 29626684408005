export const date = 'Ungültiges Datum.'
export const email = 'Ungültige E-Mail-Adresse.'
export const iban = 'Ungültige IBAN-Nummer.'
export const invalid = 'Ungültiger Wert.'
export const invalidFileType = 'Ungültiger Dateityp.'
export const maxCount = 'Dieses Feld sollte höchstens {0} Elemente enthalten.'
export const maxDate = 'Geben Sie ein Datum vor dem {0} ein.'
export const maxDecimals =
  'Dieser Wert darf höchstens {0} Dezimalstellen enthalten. Achten Sie darauf, kein Tausendertrennzeichen zu verwenden.'
export const maxFileSize = 'Wählen Sie eine Datei kleiner als {0}.'
export const maxFilesSize = 'Alle Dateien müssen kleiner als {0} sein.'
export const maxLength = 'Dieser Wert muss weniger als {0} Zeichen lang sein.'
export const maxValue = 'Dieser Wert muss kleiner oder gleich {0} sein.'
export const minCount = 'Dieses Feld sollte mindestens {0} Elemente enthalten.'
export const minDate = 'Geben Sie ein Datum nach dem {0} ein.'
export const minFileSize = 'Wählen Sie eine Datei größer als {0}.'
export const minFilesSize = 'Alle Dateien müssen größer als {0} sein.'
export const minLength = 'Dieser Wert muss mehr als {0} Zeichen lang sein.'
export const minValue =
  'Dieser Wert muss größer oder gleich {0} sein. Achten Sie darauf, kein Tausendertrennzeichen zu verwenden.'
export const pattern = 'Dieser Wert respektiert nicht das richtige Format.'
export const phone = 'Ungültige Telefonnummer.'
export const phoneCountry = 'Ungültige {0} Telefonnummer.'
export const phoneFixedLine = 'Ungültige Festnetznummer.'
export const phoneIntl = 'Stellen Sie sicher, dass Sie mit Ihrer Ländervorwahl +xx beginnen.'
export const phoneMobile = 'Ungültige Mobilfunknummer.'
export const required = 'Ein Wert ist erforderlich.'
export const requiredButton = 'Bitte klicke auf diesen Button.'
export const requiredCard = 'Bitte klicke auf {0}.'
export const requiredChoice = 'Bitte wählen Sie eine Variante.'
export const requiredChoices = 'Bitte wählen Sie eine oder mehrere Optionen aus.'
export const requiredDate = 'Ungültiges Datum.'
export const requiredDatetime = 'Ungültiges Datum und Uhrzeit.'
export const requiredDownload =
  'Alle Dateien müssen heruntergeladen worden sein, um diesen Schritt zu validieren.'
export const requiredFile = 'Bitte geben Sie eine Datei an.'
export const requiredFileMultiple = 'Bitte wählen Sie mindestens eine Datei aus.'
export const requiredSignature = 'Bitte unterschreiben Sie.'
export const requiredRating = 'Bitte wählen Sie eine Punktzahl zwischen 1 und 5.'
export const requiredChecked = 'Bitte aktivieren Sie dieses Kästchen.'

export const date = 'Neplatné datum.'
export const email = 'Neplatná e-mailová adresa.'
export const iban = 'Neplatné číslo IBAN.'
export const invalid = 'Neplatná hodnota.'
export const invalidFileType = 'Neplatný typ souboru.'
export const maxCount = 'Toto pole by mělo obsahovat nejvýše {0} položek.'
export const maxDate = 'Zadejte datum před {0}.'
export const maxDecimals =
  'Tato hodnota musí obsahovat nejvýše {0} desetinných míst. Dejte pozor, abyste nepoužili oddělovač tisíců.'
export const maxFileSize = 'Vyberte soubor menší než {0}.'
export const maxFilesSize = 'Všechny soubory musí být menší než {0}.'
export const maxLength = 'Tato hodnota musí být kratší než {0} znaků.'
export const maxValue = 'Tato hodnota musí být menší nebo rovna {0}.'
export const minCount = 'Toto pole by mělo obsahovat alespoň {0} položek.'
export const minDate = 'Zadejte datum po {0}.'
export const minFileSize = 'Vyberte soubor větší než {0}.'
export const minFilesSize = 'Všechny soubory musí být větší než {0}.'
export const minLength = 'Tato hodnota musí být delší než {0} znaků.'
export const minValue =
  'Tato hodnota musí být větší nebo rovna {0}. Dejte pozor, abyste nepoužili oddělovač tisíců.'
export const pattern = 'Tato hodnota neodpovídá správnému formátu.'
export const phone = 'Neplatné telefonní číslo.'
export const phoneCountry = 'Neplatné {0} telefonní číslo.'
export const phoneFixedLine = 'Neplatné číslo pevné linky.'
export const phoneIntl = 'Ujistěte se, že začínáte kódem země +xx.'
export const phoneMobile = 'Neplatné číslo mobilního telefonu.'
export const required = 'Hodnota je povinná.'
export const requiredButton = 'Klikněte prosím na toto tlačítko.'
export const requiredCard = 'Klikněte prosím na {0}.'
export const requiredChoice = 'Vyberte prosím jednu možnost.'
export const requiredChoices = 'Vyberte jednu nebo více možností.'
export const requiredDate = 'Platné datum je povinné.'
export const requiredDatetime = 'Platné datum a čas jsou povinné.'
export const requiredDownload = 'Všechny soubory musí být staženy k ověření tohoto kroku.'
export const requiredFile = 'Soubor je povinný.'
export const requiredFileMultiple = 'Zadejte prosím alespoň jeden soubor.'
export const requiredSignature = 'Podepište se prosím.'
export const requiredRating = 'Vyberte hodnotu mezi 1 a 5.'
export const requiredChecked = 'Zaškrtněte prosím toto políčko.'

export const date = 'Dată invalidă.'
export const email = 'Adresă de email invalidă.'
export const iban = 'Număr IBAN invalid.'
export const invalid = 'Valoare invalidă.'
export const invalidFileType = 'Tip de fișier invalid.'
export const maxCount = 'Acest câmp ar trebui să conțină cel mult {0} elemente.'
export const maxDate = 'Introduceți o dată înainte de {0}.'
export const maxDecimals =
  'Această valoare trebuie să conțină cel mult {0} zecimale. Aveți grijă să nu utilizați un separator de mii.'
export const maxFileSize = 'Selectați un fișier mai mic decât {0}.'
export const maxFilesSize = 'Toate fișierele trebuie să fie mai mici decât {0}.'
export const maxLength = 'Această valoare trebuie să fie mai mică de {0} caractere.'
export const maxValue = 'Această valoare trebuie să fie mai mică sau egală cu {0}.'
export const minCount = 'Acest câmp ar trebui să conțină cel puțin {0} elemente.'
export const minDate = 'Introduceți o dată după {0}.'
export const minFileSize = 'Selectați un fișier mai mare decât {0}.'
export const minFilesSize = 'Toate fișierele trebuie să fie mai mari decât {0}.'
export const minLength = 'Această valoare trebuie să fie mai lungă de {0} caractere.'
export const minValue =
  'Această valoare trebuie să fie mai mare sau egală cu {0}. Aveți grijă să nu utilizați un separator de mii.'
export const pattern = 'Această valoare nu respectă formatul corect.'
export const phone = 'Număr de telefon invalid.'
export const phoneCountry = 'Număr de telefon {0} invalid.'
export const phoneFixedLine = 'Număr de telefon fix invalid.'
export const phoneIntl = 'Asigurați-vă că începeți cu codul de țară +xx.'
export const phoneMobile = 'Număr de telefon mobil invalid.'
export const required = 'Este necesară o valoare.'
export const requiredButton = 'Vă rugăm să faceți clic pe acest buton.'
export const requiredCard = 'Vă rugăm să faceți clic pe {0}.'
export const requiredChoice = 'Vă rugăm să alegeți una.'
export const requiredChoices = 'Alegeți una sau mai multe.'
export const requiredDate = 'Este necesară o dată validă.'
export const requiredDatetime = 'Este necesară o dată și o oră validă.'
export const requiredDownload =
  'Toate fișierele trebuie să fie descărcate pentru a valida acest pas.'
export const requiredFile = 'Este necesar un fișier.'
export const requiredFileMultiple = 'Vă rugăm să furnizați cel puțin un fișier.'
export const requiredSignature = 'Vă rugăm să semnați.'
export const requiredRating = 'Alegeți o valoare între 1 și 5.'
export const requiredChecked = 'Vă rugăm să bifați această casetă.'

export const date = 'Neteisinga data.'
export const email = 'Neteisingas el. pašto adresas.'
export const iban = 'Neteisingas IBAN numeris.'
export const invalid = 'Neteisinga vertė.'
export const invalidFileType = 'Neteisingas failo tipas.'
export const maxCount = 'Šis laukas turėtų turėti ne daugiau kaip {0} elementus.'
export const maxDate = 'Įveskite datą prieš {0}.'
export const maxDecimals =
  'Ši vertė turi turėti ne daugiau kaip {0} dešimtųjų. Įsitikinkite, kad nenaudojate tūkstančių atskyriklio.'
export const maxFileSize = 'Pasirinkite failą, kuris yra mažesnis nei {0}.'
export const maxFilesSize = 'Visi failai turi būti mažesni nei {0}.'
export const maxLength = 'Ši vertė turi būti trumpesnė nei {0} simboliai.'
export const maxValue = 'Ši vertė turi būti mažesnė arba lygi {0}.'
export const minCount = 'Šis laukas turėtų turėti ne mažiau kaip {0} elementus.'
export const minDate = 'Įveskite datą po {0}.'
export const minFileSize = 'Pasirinkite failą, kuris yra didesnis nei {0}.'
export const minFilesSize = 'Visi failai turi būti didesni nei {0}.'
export const minLength = 'Ši vertė turi būti ilgesnė nei {0} simboliai.'
export const minValue =
  'Ši vertė turi būti didesnė arba lygi {0}. Įsitikinkite, kad nenaudojate tūkstančių atskyriklio.'
export const pattern = 'Ši vertė neatitinka tinkamo formato.'
export const phone = 'Neteisingas telefono numeris.'
export const phoneCountry = 'Neteisingas {0} telefono numeris.'
export const phoneFixedLine = 'Neteisingas fiksuoto telefono numeris.'
export const phoneIntl = 'Įsitikinkite, kad pradėjote su savo +xx šalies kodu.'
export const phoneMobile = 'Neteisingas mobiliojo telefono numeris.'
export const required = 'Reikalinga vertė.'
export const requiredButton = 'Prašome spustelėti šį mygtuką.'
export const requiredCard = 'Prašome spustelėti {0}.'
export const requiredChoice = 'Prašome pasirinkti vieną.'
export const requiredChoices = 'Pasirinkite vieną ar daugiau.'
export const requiredDate = 'Reikalinga galiojanti data.'
export const requiredDatetime = 'Reikalinga galiojanti data ir laikas.'
export const requiredDownload = 'Visi failai turi būti atsisiųsti, kad patvirtintumėte šį žingsnį.'
export const requiredFile = 'Reikalingas failas.'
export const requiredFileMultiple = 'Prašome pateikti bent vieną failą.'
export const requiredSignature = 'Prašome pasirašyti.'
export const requiredRating = 'Pasirinkite vertę nuo 1 iki 5.'
export const requiredChecked = 'Prašome pažymėti šią langelį.'

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2172_4536)">
      <circle cx="9" cy="9" r="8.25" stroke="white" stroke-width="1.5" />
      <path
        d="M9 4C9 3.44772 9.45076 2.99151 9.99539 3.08314C10.8279 3.22319 11.6253 3.53802 12.3334 4.01118C13.3201 4.67047 14.0892 5.60754 14.5433 6.7039C14.9974 7.80026 15.1162 9.00666 14.8847 10.1705C14.7186 11.0058 14.3773 11.7923 13.8877 12.48C13.5674 12.9299 12.9261 12.9261 12.5355 12.5355L9.29289 9.29289C9.10536 9.10536 9 8.851 9 8.58579L9 4Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2172_4536">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconProgress',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

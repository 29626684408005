import { legacyAllowed } from '../entities'
import type { FlowScope, RequestScope } from '../types'

import { fixInvalidElements, fixInvalidSteps } from './legacy.js'
import { buildElements, buildSteps } from './engine.js'
import { createValueGetter } from './value-getter.js'

export type GetterOptions = {
  legacy?: boolean
}

const STEPS_NAMESPACES: ReadonlyArray<keyof RequestScope> = ['data', 'user', 'signatures']
export const stepsFormBuilder = (input: any, scope: RequestScope) => {
  const legacy = legacyAllowed(scope.$flow)
  const defaultNamespace = legacy ? 'data' : undefined
  const steps = buildSteps(input, {
    locale: scope?.$locale,
    ensureSubmittable: true,
    getValue: createValueGetter(scope, STEPS_NAMESPACES, defaultNamespace),
    timeZone: scope.$company?.attributes?.time_zone
      ? scope.$company.attributes.time_zone
      : undefined,
  })

  return legacy ? fixInvalidSteps(steps) : steps
}

const OPTIONS_NAMESPACES: ReadonlyArray<keyof FlowScope> = ['options']
export const optionsFormBuilder = (input: any, scope: FlowScope) => {
  const steps = buildSteps(input, {
    locale: scope?.$locale,
    ensureSubmittable: false,
    getValue: createValueGetter(scope, OPTIONS_NAMESPACES),
    timeZone: scope.$company?.attributes?.time_zone
      ? scope.$company.attributes.time_zone
      : undefined,
  })

  return steps
}

const SCENARIO_NAMESPACES: ReadonlyArray<keyof RequestScope> = ['data', 'user']
export const scenarioElementsBuilder = (input: any, scope: RequestScope) => {
  // The whole concept of "scenario" is legacy so there is no need to
  // distinguish between legacy and non-legacy flows here.

  const elements = buildElements(input, {
    locale: scope?.$locale,
    ensureSubmittable: false,
    getValue: createValueGetter(scope, SCENARIO_NAMESPACES, 'data'),
  })

  return fixInvalidElements(elements)
}

export const date = 'Dit is geen geldige datum.'
export const email = 'Dit is geen geldig e-mail adres.'
export const iban = 'Dit is geen geldig IBAN nummer.'
export const invalid = 'Deze waarde is ongeldig.'
export const invalidFileType = 'Dit is geen geldig bestandstype.'
export const maxCount = 'Dit tekstveld moet maximaal {0} elementen bevatten.'
export const maxDate = 'Voer een datum vóór {0}.'
export const maxDecimals =
  'Deze waarde mag maximaal {0} cijfers achter de komma bevatten. Zorg ervoor dat u geen scheidingsteken voor duizendtallen gebruikt.'
export const maxFileSize = 'Selecteer een bestand met niet meer dan {0}.'
export const maxFilesSize = 'Alle bestanden moeten maximaal {0} zijn.'
export const maxLength = 'Deze waarde moet {0} tekens bevatten.'
export const maxValue = 'Deze moet kleiner dan of gelijk aan {0} zijn.'
export const minCount = 'Dit veld moet minimaal elementen {0}.'
export const minDate = 'Voer een datum na {0}.'
export const minFileSize = 'Selecteer een bestand met ten minste {0}.'
export const minFilesSize = 'Alle bestanden moeten ten minste {0} zijn.'
export const minLength = 'Deze waarde moet minimum {0} tekens bevatten.'
export const minValue =
  'Deze waarde moet groter dan of gelijk aan {0} zijn. Zorg ervoor dat u geen scheidingsteken voor duizendtallen gebruikt.'
export const pattern = 'Deze waarde volgt het juiste formaat niet.'
export const phone = 'Ongeldig telefoonnummer.'
export const phoneCountry = 'Ongeldig {0} telefoonnummer.'
export const phoneFixedLine = 'Ongeldig vaste telefoonnummer.'
export const phoneIntl = 'Geef de landcode (+ xx).'
export const phoneMobile = 'Ongeldig mobiele telefoonnummer.'
export const required = 'Gelieve een waarde in te vullen.'
export const requiredButton = 'Klik a.u.b. op deze knop.'
export const requiredCard = 'Klik a.u.b. op {0}.'
export const requiredChoice = 'Kies een waarde.'
export const requiredChoices = 'Kies minimaal één waarde.'
export const requiredDate = 'Vul een geldige.'
export const requiredDatetime = 'Vul een geldige datum en tijd in.'
export const requiredDownload = 'Alle bestanden moeten zijn gedownload om deze stap te valideren.'
export const requiredFile = 'Selecteer één bestand.'
export const requiredFileMultiple = 'Selecteer minimaal één bestand.'
export const requiredSignature = 'Gelieve te tekenen.'
export const requiredRating = 'Kies een score tussen 1 en 5.'
export const requiredChecked = 'Vink dit vakje aan.'

export const date = 'Érvénytelen dátum.'
export const email = 'Érvénytelen e-mail cím.'
export const iban = 'Érvénytelen IBAN szám.'
export const invalid = 'Érvénytelen érték.'
export const invalidFileType = 'Érvénytelen fájltípus.'
export const maxCount = 'Ez a mező legfeljebb {0} elemet tartalmazhat.'
export const maxDate = 'Adjon meg egy dátumot {0} előtt.'
export const maxDecimals =
  'Ez az érték legfeljebb {0} tizedesjegyet tartalmazhat. Vigyázzon, hogy ne használjon ezres elválasztót.'
export const maxFileSize = 'Válasszon egy fájlt, amely kisebb, mint {0}.'
export const maxFilesSize = 'Minden fájlnak kisebbnek kell lennie, mint {0}.'
export const maxLength = 'Ez az érték legfeljebb {0} karakter hosszú lehet.'
export const maxValue = 'Ez az érték kisebb vagy egyenlő kell, hogy legyen {0}.'
export const minCount = 'Ez a mező legalább {0} elemet tartalmazhat.'
export const minDate = 'Adjon meg egy dátumot {0} után.'
export const minFileSize = 'Válasszon egy fájlt, amely nagyobb, mint {0}.'
export const minFilesSize = 'Minden fájlnak nagyobbnak kell lennie, mint {0}.'
export const minLength = 'Ez az érték hosszabb kell, hogy legyen {0} karakternél.'
export const minValue =
  'Ez az érték nagyobb vagy egyenlő kell, hogy legyen {0}. Vigyázzon, hogy ne használjon ezres elválasztót.'
export const pattern = 'Ez az érték nem felel meg a megfelelő formátumnak.'
export const phone = 'Érvénytelen telefonszám.'
export const phoneCountry = 'Érvénytelen {0} telefonszám.'
export const phoneFixedLine = 'Érvénytelen vezetékes szám.'
export const phoneIntl = 'Győződjön meg róla, hogy az országkóddal +xx kezdi.'
export const phoneMobile = 'Érvénytelen mobilszám.'
export const required = 'Érték szükséges.'
export const requiredButton = 'Kérjük, kattintson erre a gombra.'
export const requiredCard = 'Kérjük, kattintson {0}.'
export const requiredChoice = 'Kérjük, válasszon egyet.'
export const requiredChoices = 'Válasszon egyet vagy többet.'
export const requiredDate = 'Érvényes dátum szükséges.'
export const requiredDatetime = 'Érvényes dátum és idő szükséges.'
export const requiredDownload = 'Az összes fájlt le kell tölteni a lépés érvényesítéséhez.'
export const requiredFile = 'Fájl szükséges.'
export const requiredFileMultiple = 'Kérjük, adjon meg legalább egy fájlt.'
export const requiredSignature = 'Kérjük, írja alá.'
export const requiredRating = 'Válasszon értéket 1 és 5 között.'
export const requiredChecked = 'Kérjük, jelölje be ezt a négyzetet.'
